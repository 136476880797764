import { useLazyQuery } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useFetchUserOffersForPTQuery } from 'api/productTemplateListsApi'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { mockProductTemplate } from 'productTemplate/components/__mocks__/__data__/mockProductTemplate'
import { GET_PRODUCT_TEMPLATE_PRICING } from 'productTemplate/queries'
import { IOrderInfo } from 'productTemplate/types/IOrderInfo'
import { isConditional } from 'server/utils/productTemplate/productVariantUtils'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useUser } from 'shared/hooks/useUser'
import { IProductSizeOption } from 'shared/types/IProductSizeOption'
import { IProductTemplate } from 'shared/types/IProductTemplate'
import { ISizePickerOption, ISizePickerOptionVariant } from 'shared/types/ISizePickerOption'
import { PTListsProductTemplate } from 'shared/types/ProductTemplateLists'
import { isomorphicLog } from 'shared/utils/logging-utils'

export type ModalType = 'new' | 'used' | undefined

export type NewProductViewType = 'ship' | 'pickup'

export type ProductTemplateContextState = {
  activeProductModal: ModalType
  isApparel: boolean
  loadingPricing: boolean
  newProductView: NewProductViewType
  orderInfo?: IOrderInfo
  productTemplate: IProductTemplate
  selectedConditionalProduct?: IProductSizeOption
  selectedInstantShip: boolean
  selectedSize?: ISizePickerOption
  setActiveProductModal(activeModal?: ModalType): void
  setInstantShip(selectedInstantShip: boolean): void
  setLowPriceConditionalProduct(conditionalProduct?: IProductSizeOption): void
  setSelectedConditionalProduct(conditionalProduct?: IProductSizeOption): void
  setSize(size: ISizePickerOption): void
  setNewProductView: Dispatch<SetStateAction<NewProductViewType>>
  userOffersForPT?: { sizes: number[]; productTemplate: PTListsProductTemplate }
}

type ProductTemplateContextProviderProps = {
  children: ReactNode
  productTemplate: IProductTemplate
  urlSize: string
}

// initialize any values the context needs here
export const ProductTemplateContext = createContext<ProductTemplateContextState>({
  activeProductModal: undefined,
  isApparel: false,
  loadingPricing: false,
  newProductView: 'ship',
  // ProductTemplatePage::getStaticProps will fill productTemplate
  // We use a mock to be able to export useProductTemplateContext
  // while assuring that downstream components understand
  // productTemplate on the context is guaranteed
  // but this mock will never surface (check the test suite to verify)
  productTemplate: mockProductTemplate,
  selectedInstantShip: true,
  setActiveProductModal: () => null,
  setInstantShip: () => null,
  setLowPriceConditionalProduct: () => null,
  setNewProductView: () => null,
  setSelectedConditionalProduct: () => null,
  setSize: () => null,
  userOffersForPT: { sizes: [], productTemplate: {} as PTListsProductTemplate },
})

export const useProductTemplateContext = (): ProductTemplateContextState =>
  useContext(ProductTemplateContext)

export const ProductTemplateContextProvider = ({
  children,
  productTemplate: productTemplateProp,
  urlSize,
}: ProductTemplateContextProviderProps) => {
  const [productTemplate, setProductTemplate] = useState(productTemplateProp)
  const defaultSizing = getDefaultSize(productTemplate, urlSize)
  const [getProductTemplatePricing, { data: pricingData, loading: loadingPricing }] = useLazyQuery(
    GET_PRODUCT_TEMPLATE_PRICING,
    {
      fetchPolicy: 'network-only', // necessary to force updates when currency changes
    },
  )
  const { currencyCode: selectedCurrency, country: selectedCountryCode } =
    useShoppingRegionContext()
  const [activeProductModal, setModal] = useState<ModalType>()
  const [newProductView, setNewProductView] = useState<NewProductViewType>('ship')
  const [selectedConditionalProduct, setSelectedConditionalProduct] = useState<IProductSizeOption>()
  const [selectedInstantShip, setInstantShip] = useState(false)
  const [selectedSize, setSize] = useState(defaultSizing || productTemplate.sizePickerDefault)
  const [prevCurrency, setPrevCurrency] = useState(selectedCurrency)
  const [prevCountry, setPrevCountry] = useState(selectedCountryCode)
  const [isOfferSizeSet, setIsOfferSizeSet] = useState(false)
  const isApparel = productTemplate.productCategory !== 'shoes'
  const isOffersEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_OFFERS)
  const { isAuthenticated, currentUser } = useUser()
  const { data: userOffersForPT, isSuccess: isUserOffersForPTSuccess } =
    useFetchUserOffersForPTQuery(
      {
        productTemplateId: productTemplateProp.id,
        currentUserId: currentUser?.id,
      },
      { skip: !isAuthenticated || !isOffersEnabled, refetchOnMountOrArgChange: true },
    )

  // pre-select the size to the lowest size in the user's offers (if any) for this PT
  if (isUserOffersForPTSuccess) {
    if (!isOfferSizeSet) {
      const offerSelectedSize = productTemplate?.sizePickerOptions?.find(
        (shoe) => shoe?.size?.value === userOffersForPT?.sizes?.[0],
      )

      if (offerSelectedSize && offerSelectedSize.size.value !== selectedSize.size.value) {
        setSize(offerSelectedSize)
        setIsOfferSizeSet(true) // only pre-select the size once
      }
    }
  }

  const updateProductTemplate = (pt: IProductTemplate) => {
    setProductTemplate(pt)
    const defaultSizing = getDefaultSize(pt, urlSize)
    if (!isOfferSizeSet) {
      setSize(defaultSizing || pt.sizePickerDefault)
    }
  }

  useEffect(() => {
    if (
      (productTemplateProp.fetchedServerSide && !pricingData) ||
      selectedCurrency !== prevCurrency ||
      selectedCountryCode !== prevCountry
    ) {
      getProductTemplatePricing({
        variables: {
          id: productTemplateProp?.id,
          slug: productTemplateProp?.slug,
          countryCode: selectedCountryCode,
          currencyCode: selectedCurrency,
        },
      })
      if (selectedCurrency !== prevCurrency) {
        setPrevCurrency(selectedCurrency)
      }
      if (selectedCountryCode !== prevCountry) {
        setPrevCountry(selectedCountryCode)
      }
    }
  }, [selectedCurrency, selectedCountryCode])

  useEffect(() => {
    const productTemplatePricing = pricingData?.getProductTemplatePricing
    if (productTemplatePricing) {
      updateProductTemplate(Object.assign({}, productTemplate, productTemplatePricing))
    }
  }, [pricingData?.getProductTemplatePricing])

  const setLowPriceConditionalProduct = (lowPriceConditionalProduct: IProductSizeOption) => {
    if (!selectedConditionalProduct && isConditional(selectedSize)) {
      setSelectedConditionalProduct(lowPriceConditionalProduct)
    }
  }

  useEffect(() => {
    if (productTemplate?.id !== productTemplateProp?.id) {
      setIsOfferSizeSet(false)
      updateProductTemplate(productTemplateProp)
    }
  }, [productTemplateProp?.id])

  const getOrderInfo = () => {
    if (selectedConditionalProduct) {
      return {
        boxCondition: selectedConditionalProduct.productBoxCondition,
        condition: selectedConditionalProduct.shoeCondition,
        details: productTemplate.details,
        expectedPriceCents: selectedConditionalProduct.price.value,
        instantShip: selectedConditionalProduct.isInstantShip,
        localizedExpectedPriceCents: selectedConditionalProduct.price.localizedValue,
        mainPictureUrl: productTemplate.pictureUrl,
        name: productTemplate.name,
        productCategory: productTemplate?.productCategory,
        productId: selectedConditionalProduct.productId,
        productTemplateId: Number(productTemplate.id),
        productType: 'Conditional',
        size: selectedConditionalProduct.size.value,
        sizeDisplay: selectedConditionalProduct.size.display,
      }
    }
    if (selectedSize && activeProductModal) {
      const selectedProduct: ISizePickerOptionVariant = selectedSize[activeProductModal]!
      const priceType = selectedInstantShip ? 'instantShipLowestPriceCents' : 'lowestPriceCents'
      if (activeProductModal === 'new' && selectedProduct.shoeCondition !== 'new_no_defects') {
        return null
      }
      return {
        boxCondition: selectedProduct.boxCondition,
        condition: selectedProduct.shoeCondition,
        details: productTemplate.details,
        expectedPriceCents: selectedProduct[priceType]!.value,
        instantShip: selectedInstantShip,
        localizedExpectedPriceCents: selectedProduct[priceType]!.localizedValue,
        mainPictureUrl: productTemplate.pictureUrl,
        name: productTemplate.name,
        productCategory: productTemplate?.productCategory,
        productId: null,
        productTemplateId: Number(productTemplate.id),
        productType: activeProductModal,
        size: selectedSize.size.value,
        sizeDisplay: selectedSize.size.display,
      }
    }
  }

  const setActiveProductModal = (activeModal: ModalType) => {
    setModal(activeModal)
    const isInstantShip = activeModal
      ? (selectedSize[activeModal]?.instantShipLowestPriceCents?.value || Infinity) <=
        (selectedSize[activeModal]?.lowestPriceCents?.value || Infinity)
      : false
    setInstantShip(isInstantShip)
    setSelectedConditionalProduct(undefined)
    if (activeModal) {
      Bugsnag.leaveBreadcrumb(`User clicked buy ${activeModal} button in buy button row`, {
        slug: productTemplate.slug,
        size: selectedSize.size.display,
      })
      try {
        sendTrackingEvent(
          `PDP_BUY_${activeModal.toUpperCase()}_TAP` as 'PDP_BUY_NEW_TAP' | 'PDP_BUY_USED_TAP',
          {
            product_template_slug: productTemplate.slug,
          },
        )
      } catch (e) {
        isomorphicLog(`Error sending pdp buy ${activeModal} tap: ${JSON.stringify(e)}`)
      }
    }
  }

  const state: ProductTemplateContextState = {
    activeProductModal,
    isApparel,
    loadingPricing,
    newProductView,
    orderInfo: getOrderInfo(),
    productTemplate,
    selectedConditionalProduct,
    selectedInstantShip,
    selectedSize,
    setActiveProductModal,
    setInstantShip,
    setLowPriceConditionalProduct,
    setSelectedConditionalProduct,
    setSize,
    setNewProductView,
    userOffersForPT,
  }
  return <ProductTemplateContext.Provider value={state}>{children}</ProductTemplateContext.Provider>
}

const getDefaultSize = (productTemplate: IProductTemplate, urlSize: string) => {
  return productTemplate?.sizePickerOptions?.find(
    (shoe) => shoe?.size?.display === urlSize && shoe.new,
  )
}
