import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useEffect, useRef, useState } from 'react'

import { t } from 'localization'
import { RotatingCaret } from 'shared/components'
import Button from 'shared/components/Button'
import MobileOverlay from 'shared/components/MobileOverlay'
import PDPDivider from 'shared/components/PDPDivider'
import { ProductQA } from 'shared/dataAttributes'
import { ISizePickerOption } from 'shared/types/ISizePickerOption'

const TP = 'productTemplate.components.ProductPicker.SizePickerMobile'

import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import { ExperimentState } from 'experiments/state/ExperimentReducer'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { ShoppingRegionSelector } from 'productTemplate/components/ProductPicker/ShoppingRegionSelector'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { getProductSizeCategory } from 'productTemplate/utils/getProductSizeCategory'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import BuyButtonRow from './BuyButtonRow'
import {
  MobileOverlayPanel,
  MobileSizePickerModalTitle,
  SizeInputField,
  SizeLabel,
  SizeOptionTile,
  SizeOptionWrapper,
} from './ProductPickerStyles'

interface ISizePickerMobileProps {
  sizePickerOptions: ISizePickerOption[]
  experimentState: ExperimentState
  handleSizeOptionClick: (option: ISizePickerOption) => void
}

const SizePickerMobile = ({
  sizePickerOptions,
  experimentState,
  handleSizeOptionClick,
}: ISizePickerMobileProps) => {
  const regionSelectorPlacement: EXPERIMENT_NAME = 'web_fc_fully_landed_pricing_copy_placement_test'
  const enableExperiments = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_EXPERIMENTS)
  const enablePricingCopyPlacementExperiment = useFeatureFlag(
    FeatureFlag.TEMP_WEB_FC_ENABLE_FULLY_LANDED_PRICING_COPY_EXPERIMENT,
  )
  const contentEl = useRef<HTMLDivElement>(null)
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const { activeProductModal, productTemplate, selectedSize, isApparel } =
    useProductTemplateContext()

  const { newLowestPrice, sizeCategory, usedLowestPrice } = productTemplate

  const handleDropdownClick = () => {
    setIsSelectorOpen(!isSelectorOpen)
  }

  useEffect(() => {
    if (isSelectorOpen && contentEl.current) {
      disableBodyScroll(contentEl.current)
    } else if (contentEl.current) {
      enableBodyScroll(contentEl.current)
    }
    return () => {
      if (contentEl.current) {
        disableBodyScroll(contentEl.current)
      }
    }
  }, [isSelectorOpen, contentEl.current])
  useEffect(() => setIsSelectorOpen(false), [activeProductModal])

  const lowestPrice = newLowestPrice ? newLowestPrice.display : usedLowestPrice?.display

  return (
    <>
      <SizeInputField data-qa={ProductQA.MobileSelectSizeButton} onClick={handleDropdownClick}>
        <span>
          {t(`${TP}.mobileSelectSizeButton`, {
            defaultValue: 'Size {size}',
            size: selectedSize?.size.display,
          })}
        </span>
        <RotatingCaret isOpen={isSelectorOpen} color="black" />
      </SizeInputField>
      {lowestPrice && (
        <Button
          buttonType="primary2"
          $fill
          onClick={handleDropdownClick}
          qaAttr={ProductQA.MobileBuyFromButton}
          data-testid="buy-new-button"
        >
          {t(`${TP}.mobileBuyFromButton`, {
            defaultValue: 'FROM {lowestPrice}',
            lowestPrice,
          }) + (isApparel ? '' : '+')}
        </Button>
      )}
      <MobileOverlay
        isOpen={isSelectorOpen}
        title={
          <MobileSizePickerModalTitle data-qa={ProductQA.MobileOverlayShoeTitle}>
            {productTemplate.name}
          </MobileSizePickerModalTitle>
        }
        onClose={handleDropdownClick}
      >
        <MobileOverlayPanel ref={contentEl}>
          {enableExperiments &&
            enablePricingCopyPlacementExperiment &&
            experimentState[regionSelectorPlacement]?.groupAssigned === 'test' && (
              <ShoppingRegionSelector />
            )}
          <SizeLabel data-qa={ProductQA.MobileOverlayGenderTitle}>
            {t(`${TP}.mobileOverlayGenderTitle`, {
              defaultValue: 'US {sizeCategory}s Size',
              sizeCategory: getProductSizeCategory(sizeCategory),
            })}
          </SizeLabel>
          <SizeOptionWrapper data-qa={ProductQA.MobileSizePicker}>
            {sizePickerOptions.map((option) => (
              <SizeOptionTile
                isSelected={option.size.value === selectedSize?.size.value}
                onClick={() => handleSizeOptionClick(option)}
                key={option.size.value}
              >
                {option.size.display}
              </SizeOptionTile>
            ))}
          </SizeOptionWrapper>
        </MobileOverlayPanel>

        <PDPDivider />
        <MobileOverlayPanel>
          <BuyButtonRow />
        </MobileOverlayPanel>
      </MobileOverlay>
    </>
  )
}

export default SizePickerMobile
