/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { AlgoliaIndex } from 'search'
import AlgoliaClient from 'search/components/AlgoliaSearchClient'
import ProductHits, { Hits } from 'search/components/ProductHits'

type TrendingProductsProps = {
  hidePrices: boolean
  hits?: any[]
  isAlgolia?: boolean
}

export const TrendingProducts = ({ hidePrices, hits, isAlgolia }: TrendingProductsProps) => {
  if (isAlgolia === false) {
    return <Hits hidePrices={hidePrices} hits={hits} isAlgolia={false} />
  }
  return (
    <InstantSearch indexName={AlgoliaIndex.Trending} searchClient={AlgoliaClient}>
      <Configure hitsPerPage={8} distinct />
      <ProductHits hidePrices={hidePrices} />
    </InstantSearch>
  )
}

export default TrendingProducts
