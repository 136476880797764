import { ReactNode } from 'react'
import styled from 'styled-components'

import { BlurredBackDropSideSheet } from 'shared/components/BlurredBackDrop'
import { colors, media, zIndex } from 'shared/lib'

type ModalProps = {
  children?: ReactNode
  hasPadding?: boolean
  isOpen: boolean
  noBorder?: boolean
  widthOverride?: string
}

export const SideSheetModal = ({
  children,
  hasPadding = true,
  isOpen,
  noBorder,
  widthOverride,
}: ModalProps) => {
  return isOpen ? (
    <ModalBlurredBackDrop data-dismiss-on-click isActive={isOpen}>
      <Content widthOverride={widthOverride} hasPadding={hasPadding} noBorder={noBorder}>
        {children}
      </Content>
    </ModalBlurredBackDrop>
  ) : null
}

export default SideSheetModal

const ModalBlurredBackDrop = styled(BlurredBackDropSideSheet)`
  ${media.large`
    z-index: ${zIndex.justOverNav};
  `}
`
const Content = styled.div<{ widthOverride?: string; hasPadding?: boolean; noBorder?: boolean }>`
  ${({ noBorder }) => (noBorder ? '' : `border: 1px solid ${colors.FC2_BLACK};`)}
  background-color: ${colors.FC2_WHITE};
  min-height: 20%;
  margin: 20px;
  padding: ${({ hasPadding }) => (hasPadding ? '20px' : 0)};
  width: 100%;
  z-index: ${zIndex.sideSheetModal};

  ${({ widthOverride }) =>
    media.large`
      width: ${widthOverride ? widthOverride : '445px'};
    `}
`
