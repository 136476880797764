import { gql } from '@apollo/client'
import { isEmpty, omitBy, pipe } from 'lodash/fp'
import Head from 'next/head'
import { PartialProductTemplate } from 'shared/types/IProductTemplate'
import { financial } from 'shared/utils/currency-utils'

interface IProductTemplateStructuredDataProps {
  productTemplate: PartialProductTemplate
  locale: string
}

const formatSchema = pipe(omitBy(isEmpty), JSON.stringify)

const ProductTemplateStructuredData = ({
  productTemplate: {
    name,
    pictureUrl,
    brand,
    releaseDate,
    sku,
    details,
    story,
    isNewInStock,
    newHighestPrice,
    newLowestPrice,
    newCount,
    offers,
  },
  locale,
}: IProductTemplateStructuredDataProps) => {
  if (!isNewInStock) {
    return null
  }

  const currency = locale === 'en-jp' || locale === 'ja-jp' ? 'JPY' : 'USD'

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name,
    image: pictureUrl,
    releaseDate: releaseDate.value,
    brand: {
      '@type': 'Brand',
      name: brand,
    },
    model: name,
    sku,
    color: details,
    description: story ? story : `${name} - ${brand}`,
    offers: isNewInStock
      ? {
          '@type': 'AggregateOffer',
          availability: isNewInStock ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock',
          highPrice:
            newHighestPrice && financial({ hideCents: true })(newHighestPrice.localizedValue),
          lowPrice: newLowestPrice && financial({ hideCents: true })(newLowestPrice.localizedValue),
          priceCurrency: currency,
          offerCount: newCount,
          offers: offers
            ? offers.map((offer) => ({
                '@type': 'Offer',
                ...offer,
                priceValidUntil: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
              }))
            : [],
        }
      : null,
  }
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: formatSchema(schema),
        }}
      />
    </Head>
  )
}

ProductTemplateStructuredData.fragments = {
  productTemplate: gql`
    fragment ProductTemplateStructuredDataProductTemplate on ProductTemplate {
      name
      pictureUrl
      brand
      releaseDate {
        value
      }
      sku
      details
      story
      newCount
      isNewInStock
      newHighestPrice {
        localizedValue
        value
      }
      newLowestPrice {
        localizedValue
        value
      }
      offers {
        sku
        itemCondition
        price
        priceCurrency
        size
        availability
      }
    }
  `,
}

export default ProductTemplateStructuredData
