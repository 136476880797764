/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data

import { gql } from '@apollo/client'
import {
  addBreadCrumbPositions,
  buildPLPHeadline,
  buildPLPMetaDescription,
  buildPLPTitle,
  canonicalSlugMap,
  createListItem,
  mapToListItems,
} from 'head/utils/seoHelpers'
import { getLocalePath, t } from 'localization'
import { setLocalizedHTMLAnchorLinks } from 'localization/utils/locale-utils'
import { get, getOr, pipe } from 'lodash/fp'
import { logSpecificPageView } from 'mParticle/mParticleService'
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import ErrorPage from 'pages/_error'
import { useEffect } from 'react'
import { SearchResults, SearchState } from 'react-instantsearch-core'
import { AlgoliaIndex } from 'search'
import ProductListPageBreadcrumbs from 'search/components/ProductListPageBreadcrumbs'
import IAlgoliaQuery from 'search/types/IAlgoliaQuery'
import {
  combineFiltersAndNumericFilters,
  formatAlgoliaFilters,
  formatAlgoliaNumericFilters,
  getActiveFilterTypes,
} from 'search/utils/formatAlgoliaFilters'
import hideFilters from 'search/utils/hideFilters'
import { isAccessoriesPresent, isClothingPresent } from 'search/utils/isCategoryPresent'
import Divider from 'shared/components/Divider'
import Grid, { GridConfig } from 'shared/components/Grid'
import Spinner from 'shared/components/Spinner'
import PageContextProvider from 'shared/contexts/PageContextProvider'
import SpecificPageTrackingEvents from 'shared/enums/SpecificPageTrackingEvents'
import { media } from 'shared/lib'
import append from 'shared/lib/append'
import prepend from 'shared/lib/prepend'
import styled from 'styled-components'
import { trackSnapchatPageView } from 'tracking/snapchatService'

const TP = 'featureFlags.providers.FEatureFlagProvider'

export const BREAD_CRUMB_PARTS = gql`
  fragment BreadCrumbParts on BreadCrumb {
    description
    name
    slug
    algoliaQuery {
      indexName
      filters {
        brand_name
        silhouette
        color
        single_gender
        designer
        category
        product_category
        product_type
        shoe_condition
        collection_slugs
      }
      numeric_filters {
        is_under_retail
      }
      query
      range {
        lowest_price_cents_usd {
          min
          max
        }
      }
    }
  }
`

export const GET_BREADCRUMB_INFO = gql`
  query getBreadCrumbInfoBySlug($slug: String!) {
    getBreadCrumbInfoBySlug(slug: $slug) {
      ...BreadCrumbParts
      fullPath {
        ...BreadCrumbParts
      }
    }
  }
  ${BREAD_CRUMB_PARTS}
`

export interface GetBreadcrumbInfoVars {
  slug: string
}
export interface GetBreadcrumbInfoData {
  getBreadCrumbInfoBySlug: IBreadCrumb
}
interface IBreadCrumb {
  description: string
  name: string
  slug: string
  algoliaQuery: IAlgoliaQuery
  fullPath: IBreadCrumb[]
}
type SuccessProps = {
  data: GetBreadcrumbInfoData
  hostname?: string | undefined
  initialSearchState: SearchState
  loading?: boolean
  queryParamSearchState: SearchState
  resultsState: SearchResults
  slug?: string
  trendingResultsState: SearchResults | null
}
type ErrorProps = {
  error?: unknown
}

type ConsumerSearchProps = {
  consumerSearchResultState?: any
  consumerSearchTrendingResults?: any
}

export type FilteredGridPageProps = SuccessProps & ErrorProps & ConsumerSearchProps

const StyledDivider = styled(Divider)`
  ${media.large`
    margin-top: 80px;
  `}
`

const createBreadcrumbList = (hostname: string, locale?: string) => (crumbs: IBreadCrumb[]) =>
  pipe(
    prepend([
      createListItem(
        `${hostname}${locale ? getLocalePath('', locale) : ''}`,
        t(`${TP}.flightClub`, 'Flight Club'),
      ),
    ]),
    append(mapToListItems(hostname, locale)(crumbs)),
    addBreadCrumbPositions,
  )([])

const isErrorProps = (props: FilteredGridPageProps): props is ErrorProps => {
  return (props as ErrorProps).error !== undefined
}

export const FilteredGridPage: NextPage<FilteredGridPageProps> = (props) => {
  const {
    data,
    error,
    hostname = '',
    initialSearchState,
    loading,
    queryParamSearchState,
    resultsState,
    slug,
    trendingResultsState,
    consumerSearchResultState,
    consumerSearchTrendingResults,
  } = props

  useEffect(() => {
    if (!error && !loading) {
      logSpecificPageView({
        slug,
        pageName: SpecificPageTrackingEvents.FilteredGridPage,
        slugType: 'product_list',
      })
      trackSnapchatPageView()
    }
  }, [error, loading, slug])

  const router = useRouter()

  if (isErrorProps(props)) {
    if (error) {
      const status = getOr(404, 'graphQLErrors[0].extensions.response.status', error)
      return <ErrorPage statusCode={status} />
    }
    return null
  }

  if (loading) {
    return <Spinner showSpinner={loading} threshold={1000} />
  }

  const breadcrumb: IBreadCrumb = get('getBreadCrumbInfoBySlug', data)
  const { name, description, algoliaQuery, fullPath = [] } = breadcrumb
  const numericFilters = get('numeric_filters', algoliaQuery)
  const numericFilterTypes = getActiveFilterTypes(numericFilters)
  const activeFilterTypes = getActiveFilterTypes(get('filters', algoliaQuery))
  const productCategory = get('filters.product_category', algoliaQuery)
  const filterString = formatAlgoliaFilters(activeFilterTypes, algoliaQuery.filters)
  const numericFilterString = formatAlgoliaNumericFilters(numericFilterTypes, numericFilters)

  const combinedFiltersString = combineFiltersAndNumericFilters(filterString, numericFilterString)

  const hiddenFilters = hideFilters(algoliaQuery.filters, {
    hideBrand: name === 'Yeezy',
  })

  const config: GridConfig = {
    context: {
      hiddenFilters,
      // filter syntax per algolia:
      // https://www.algolia.com/doc/api-reference/api-parameters/filters/
      filters: combinedFiltersString,
      index: algoliaQuery.indexName || AlgoliaIndex.Relevance,
      hasNewProductOnly:
        isClothingPresent(productCategory) || isAccessoriesPresent(productCategory),
    },
  }
  const breadcrumbList = createBreadcrumbList(hostname, router?.locale)(fullPath)

  return (
    <>
      <PageContextProvider value={SpecificPageTrackingEvents.FilteredGridPage}>
        <Head>
          <title>
            {t(`${TP}.pageTitle`, {
              defaultValue: '{title} | Flight Club',
              title: buildPLPTitle(fullPath),
            })}
          </title>
          <meta
            name="description"
            content={buildPLPMetaDescription(fullPath, resultsState?.content?.hits[0]?.name)}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: breadcrumbList,
              }),
            }}
          />
          {canonicalSlugMap.has(slug as string) && (
            <link
              key="canonical"
              rel="canonical"
              href={`https://www.flightclub.com${getLocalePath(
                `${canonicalSlugMap.get(slug as string)}${
                  router?.query?.page && `?page=${router?.query?.page}`
                }`,
                router?.locale,
              )}`}
            />
          )}
        </Head>

        <Grid
          title={buildPLPHeadline(fullPath)}
          description={setLocalizedHTMLAnchorLinks(description, router?.locale)}
          config={config}
          resultsState={resultsState}
          initialSearchState={initialSearchState}
          queryParamSearchState={queryParamSearchState}
          noResultsState={trendingResultsState}
          consumerSearchResultState={consumerSearchResultState}
          consumerSearchTrendingResults={consumerSearchTrendingResults}
        />
        {fullPath.length > 1 && (
          <>
            <StyledDivider data-testid="plpDivider" />
            <ProductListPageBreadcrumbs breadcrumbPath={fullPath} />
          </>
        )}
      </PageContextProvider>
    </>
  )
}
