import { ApolloError } from '@apollo/client'
import { filter } from 'graphql-anywhere'
import debounce from 'lodash/debounce'
import toLower from 'lodash/toLower'
import Head from 'next/head'
import { useEffect } from 'react'

import { buildPDPMetaDescription, buildTitle } from 'head/utils/seoHelpers'
import { t } from 'localization'
import { logViewDetail } from 'mParticle/ecommerceMParticleEvents'
import { logSpecificPageView } from 'mParticle/mParticleService'
import ErrorPage from 'pages/_error'
import { ProductTemplateLayout } from 'productTemplate'
import ProductBreadcrumbs from 'productTemplate/components/ProductBreadcrumbs'
import { ProductRecommendations } from 'productTemplate/components/ProductRecommendations'
import { ProductTemplateContextProvider } from 'productTemplate/components/ProductTemplateContextProvider'
import ProductTemplateStructuredData from 'productTemplate/components/ProductTemplateStructuredData'
import SeoBreadcrumbList from 'productTemplate/components/SeoBreadcrumbList'
import Divider from 'shared/components/Divider'
import Spinner from 'shared/components/Spinner'
import PageContextProvider from 'shared/contexts/PageContextProvider'
import SpecificPageTrackingEvents from 'shared/enums/SpecificPageTrackingEvents'
import { renderUntil } from 'shared/hocs/render'
import { useAuth } from 'shared/hooks/useAuth'
import { useUser } from 'shared/hooks/useUser'
import { Breadcrumb } from 'shared/types/Breadcrumb'
import { IProductTemplate } from 'shared/types/IProductTemplate'
import { startCase } from 'shared/utils/formatting-utils'
import { trackSnapchatPageView } from 'tracking/snapchatService'
import { useTikTokPixelTrackEvent } from 'head/hooks/useTikTokPixelTrackEvent'

const TP = 'productTemplate.ProductTemplatePage'

type MobileBreadcrumbs = {
  breadcrumbPath?: Breadcrumb[] | null
  name: string
}

const MobileBreadcrumbComponent = ({ breadcrumbPath, name }: MobileBreadcrumbs) => {
  return (
    <>
      <Divider data-testid="PDPBreadcrumbsDivider" />
      <ProductBreadcrumbs breadcrumbPath={breadcrumbPath} name={name} />
    </>
  )
}

const MobileBreadcrumbs = renderUntil.large(MobileBreadcrumbComponent)

export type ProductPageProps = {
  hostname?: string
  pathname?: string
  errorStatus?: string
  error?: ApolloError
  loading?: boolean
  productTemplate?: IProductTemplate
  locale: string
  urlSize: string
}

export const ProductTemplatePage = ({
  hostname,
  pathname,
  errorStatus,
  error,
  loading,
  productTemplate,
  locale,
  urlSize,
}: ProductPageProps) => {
  const { currentUserType } = useUser()
  const { logoutUser } = useAuth()
  const { trackTikTokPDPView } = useTikTokPixelTrackEvent()

  if (loading) {
    return <Spinner showSpinner={loading} threshold={1000} />
  }

  if (errorStatus || error || !productTemplate) {
    const status = error?.graphQLErrors?.[0]?.extensions?.response?.status || 404
    return <ErrorPage statusCode={status} />
  }

  useEffect(() => {
    if (productTemplate) {
      const delayTrackTikTokPDPView = debounce(() => {
        trackTikTokPDPView({
          contentCategory: brand,
          contentId: sku,
          contentName: name,
          price: productTemplate?.newLowestPrice?.value,
        })
      }, 500)

      const { name, brand, sku, slug } = productTemplate
      logSpecificPageView({
        slug,
        pageName: SpecificPageTrackingEvents.ProductTemplatePage,
        slugType: 'product_template',
      })
      if (productTemplate?.newLowestPrice?.value) {
        logViewDetail({
          name,
          brandName: brand,
          sku,
          priceCents: productTemplate?.newLowestPrice?.value,
        })
      }
      trackSnapchatPageView({ sku })
      delayTrackTikTokPDPView()
    }
  }, [productTemplate?.id])

  /**
   * BUY1-354
   * Log guest users out when they reach here so that checkout is guaranteed to start
   * fresh and not have previous guest user logged in.
   */
  useEffect(() => {
    if (currentUserType === 'registeredGuest') {
      logoutUser()
    }
  }, [currentUserType])

  const { name, brand = '', sku = '', details = '', breadcrumbPath = [] } = productTemplate

  return (
    <PageContextProvider value={SpecificPageTrackingEvents.ProductTemplatePage}>
      <ProductTemplateContextProvider productTemplate={productTemplate} urlSize={urlSize}>
        <Head data-testid="product-template-page">
          <title>
            {t(`${TP}.pageTitle`, {
              defaultValue: '{name} | Flight Club',
              name: buildTitle([startCase(name), startCase(brand), sku, toLower(details)]),
            })}
          </title>
          <meta
            name="description"
            content={buildPDPMetaDescription({
              brand,
              category: productTemplate?.productCategory,
              name,
            })}
          />
          <meta name="robots" content="max-image-preview:large" />
        </Head>
        <SeoBreadcrumbList
          listData={filter(SeoBreadcrumbList.fragments.seoBreadcrumbList, {
            breadcrumbPath,
            hostname: hostname!,
            pathname: pathname!,
            name,
          })}
        />
        <ProductTemplateStructuredData
          locale={locale}
          productTemplate={filter(
            ProductTemplateStructuredData.fragments.productTemplate,
            productTemplate,
          )}
        />
        <ProductTemplateLayout />
        <ProductRecommendations productTemplateId={productTemplate.id} />
        <MobileBreadcrumbs breadcrumbPath={breadcrumbPath} name={name} />
      </ProductTemplateContextProvider>
    </PageContextProvider>
  )
}
