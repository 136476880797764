import { useMediaQuery } from 'react-responsive'

import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import {
  useExperimentState,
  useLogExperiment,
  useStartExperiment,
} from 'experiments/hooks/useExperiment'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { ProductQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { sizes } from 'shared/lib/media'

import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import PaymentPromotional from 'productTemplate/components/PaymentPromotional'
import { getProductSizeCategory } from 'productTemplate/utils/getProductSizeCategory'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { ISizePickerOption } from 'shared/types/ISizePickerOption'
import OutOfStockNotice from './OutOfStockNotice'
import {
  ProductPickerWrapper,
  SizingLabel,
  StyledSizeGuide,
  TopLabelSection,
} from './ProductPickerStyles'
import ProductSideSheet from './ProductSideSheet'
import { ShoppingRegionSelector } from './ShoppingRegionSelector'
import SizePickerDesktop from './SizePickerDesktop'
import SizePickerMobile from './SizePickerMobile'

const TP = 'productTemplate.components.ProductPicker.ProductPicker'

export const ProductPicker = () => {
  const regionSelectorPlacement: EXPERIMENT_NAME = 'web_fc_fully_landed_pricing_copy_placement_test'
  const enablePricingCopyPlacementExperiment = useFeatureFlag(
    FeatureFlag.TEMP_WEB_FC_ENABLE_FULLY_LANDED_PRICING_COPY_EXPERIMENT,
  )
  const enableExperiments = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_EXPERIMENTS)
  useStartExperiment(regionSelectorPlacement, !enablePricingCopyPlacementExperiment)
  useLogExperiment(regionSelectorPlacement, !enablePricingCopyPlacementExperiment)
  const experimentState = useExperimentState()
  const isMobile = useMediaQuery({ maxWidth: sizes.large })
  const {
    productTemplate: { sizeCategory, sizePickerOptions, slug, localizedSpecialDisplayPriceCents },
    selectedSize,
    isApparel,
    setSize,
  } = useProductTemplateContext()
  const { currencyCode } = useShoppingRegionContext()

  // FC2-1703 - temporarily hide size guide
  const showSizeGuide = false
  // const showSizeGuide = productCategory ? productCategory === 'shoes' : true

  const isMarketPricing = useFeatureFlag(FeatureFlag.MARKET_PRICING)

  const handleSizeOptionClick = (option: ISizePickerOption) => {
    setSize(option)
    sendTrackingEvent('PDP_SIZE_TAP', {
      product_template_slug: slug,
      size: option.size.display,
      under_retail:
        isApparel ||
        (option.new?.lowestPriceCents?.value || -Infinity) < localizedSpecialDisplayPriceCents.value
          ? true
          : false,
      price: option.new?.lowestPriceCents?.value || '',
      currency_code: currencyCode,
      new_standard_price: option.new?.lowestPriceCents?.value || '',
      used_price: option.used?.lowestPriceCents?.value || '',
    })
  }

  const lowestPrice = (() => {
    const newOrUsedPricing = selectedSize?.new ? 'new' : 'used'
    if (isMarketPricing) {
      if (
        selectedSize?.[newOrUsedPricing]?.lowestPriceCents?.localizedValue <
        (selectedSize?.[newOrUsedPricing]?.instantShipLowestPriceCents?.localizedValue || Infinity)
      ) {
        return selectedSize?.[newOrUsedPricing]?.lowestPriceCents?.localizedValue
      }
      return selectedSize?.[newOrUsedPricing]?.instantShipLowestPriceCents?.localizedValue
    }
    return selectedSize?.[newOrUsedPricing]?.instantShipLowestPriceCents?.localizedValue
  })()

  const sizeLabel = () => {
    switch (sizeCategory) {
      case 'youth':
      case 'infant':
        return t(`${TP}.buyNewSectionLabelYouth`, {
          defaultValue: 'Select US {sizeCategory} Size',
          sizeCategory: getProductSizeCategory(sizeCategory),
        })
      default:
        return isApparel
          ? t(`${TP}.buyNewSectionApparelLabel`, {
              defaultValue: `Select US Size`,
            })
          : t(`${TP}.buyNewSectionLabel`, {
              defaultValue: `Select US {sizeCategory}'s Size`,
              sizeCategory: `${getProductSizeCategory(sizeCategory)}`,
            })
    }
  }

  if (!sizePickerOptions || !sizePickerOptions.length) {
    return (
      <ProductPickerWrapper>
        <OutOfStockNotice />
      </ProductPickerWrapper>
    )
  }

  return (
    <ProductPickerWrapper>
      <ProductSideSheet experimentState={experimentState} />
      <TopLabelSection>
        <SizingLabel data-qa={ProductQA.BuyNewSectionLabel}>{sizeLabel()}</SizingLabel>
        {showSizeGuide && (
          <div>
            <StyledSizeGuide />
          </div>
        )}
      </TopLabelSection>
      {isMobile ? (
        <SizePickerMobile
          sizePickerOptions={sizePickerOptions}
          experimentState={experimentState}
          handleSizeOptionClick={handleSizeOptionClick}
        />
      ) : (
        <SizePickerDesktop
          sizePickerOptions={sizePickerOptions}
          handleSizeOptionClick={handleSizeOptionClick}
        />
      )}
      <PaymentPromotional
        lowestPrice={lowestPrice}
        amount={selectedSize?.new?.lowestPriceCents?.value}
      />
      {enableExperiments && enablePricingCopyPlacementExperiment ? (
        experimentState[regionSelectorPlacement]?.groupAssigned === 'control' && (
          <ShoppingRegionSelector />
        )
      ) : (
        <ShoppingRegionSelector />
      )}
    </ProductPickerWrapper>
  )
}
