import { gql } from '@apollo/client'
import ProductTemplateStructuredData from 'productTemplate/components/ProductTemplateStructuredData'

const PRODUCT_TEMPLATE_PRICE_DISPLAY_PARTS = gql`
  fragment ProductTemplatePriceDisplayParts on Price {
    # ssrDisplay is a temporary workaround to process the display value on the server side
    # https://github.com/apollographql/react-apollo/issues/3338
    # display(currency: $currency, useGrouping: false, hideEmptyCents: true) @client
    display(useGrouping: true, hideEmptyCents: true)
    localizedValue
    value
  }
`

const SIZE_PICKER_OPTION_PARTS = gql`
  fragment SizePickerOptionParts on SizePickerOption {
    size {
      display
      value
    }
    new {
      shoeCondition
      boxCondition
      lowestPriceCents {
        ...ProductTemplatePriceDisplayParts
      }
      instantShipLowestPriceCents {
        ...ProductTemplatePriceDisplayParts
      }
    }
    used {
      shoeCondition
      boxCondition
      lowestPriceCents {
        ...ProductTemplatePriceDisplayParts
      }
      instantShipLowestPriceCents {
        ...ProductTemplatePriceDisplayParts
      }
    }
  }
`

export const GET_PRODUCT_TEMPLATE = gql`
  query getProductTemplate($slug: String!, $countryCode: String, $currencyCode: String) {
    getProductTemplate(slug: $slug, countryCode: $countryCode, currencyCode: $currencyCode) {
      ...ProductTemplateStructuredDataProductTemplate
      brand
      breadcrumbPath {
        slug
        name
      }
      description
      details
      id
      isNewInStock
      isOfferable
      localizedSpecialDisplayPriceCents {
        ...ProductTemplatePriceDisplayParts
      }
      minimumOfferCents
      name
      newCount
      newHighestPrice {
        ...ProductTemplatePriceDisplayParts
      }
      newLowestPrice {
        ...ProductTemplatePriceDisplayParts
      }
      newSizes {
        productTemplateId
        size {
          value
          display
        }
        shoeCondition
        boxCondition
        lowestPriceOption {
          price {
            value
            ...ProductTemplatePriceDisplayParts
          }
          isAvailable
        }
        instantShipPriceOption {
          price {
            value
            ...ProductTemplatePriceDisplayParts
          }
          isAvailable
        }
        isInstantShip
      }
      pictures
      pictureUrl
      productCategory
      productTaxonomy {
        name
        slug
        urlOverride
      }
      releaseDate {
        shortDisplay
      }
      silhouette
      sizeCategory
      sizePickerDefault {
        ...SizePickerOptionParts
      }
      sizePickerOptions {
        ...SizePickerOptionParts
      }
      sku
      slug
      specialType
      storyHtml
      usedLowestPrice {
        ...ProductTemplatePriceDisplayParts
      }
    }
  }
  ${PRODUCT_TEMPLATE_PRICE_DISPLAY_PARTS}
  ${SIZE_PICKER_OPTION_PARTS}
  ${ProductTemplateStructuredData.fragments.productTemplate}
`

export const GET_PRODUCT_TEMPLATE_PRICING = gql`
  query getProductTemplatePricing($id: ID!, $slug: String!, $countryCode: String) {
    getProductTemplatePricing(id: $id, slug: $slug, countryCode: $countryCode) {
      isNewInStock
      newCount
      newHighestPrice {
        ...ProductTemplatePriceDisplayParts
      }
      newLowestPrice {
        ...ProductTemplatePriceDisplayParts
      }
      newSizes {
        productTemplateId
        size {
          value
          display
        }
        shoeCondition
        boxCondition
        lowestPriceOption {
          price {
            value
            ...ProductTemplatePriceDisplayParts
          }
          isAvailable
        }
        instantShipPriceOption {
          price {
            value
            ...ProductTemplatePriceDisplayParts
          }
          isAvailable
        }
        isInstantShip
      }
      sizePickerDefault {
        ...SizePickerOptionParts
      }
      sizePickerOptions {
        ...SizePickerOptionParts
      }
      usedLowestPrice {
        ...ProductTemplatePriceDisplayParts
      }
    }
  }
  ${PRODUCT_TEMPLATE_PRICE_DISPLAY_PARTS}
  ${SIZE_PICKER_OPTION_PARTS}
`
