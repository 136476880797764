import styled from 'styled-components'

import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { OfferModalDetails } from 'offers/types'
import { getRemainingOfferTime } from 'offers/utils'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { ProductQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts } from 'shared/lib'
import { formatMoneyObject } from 'shared/lib/formatMoneyObject'
import { ISizePickerOptionVariant } from 'shared/types/ISizePickerOption'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getActiveOffers, setIsOffersModalOpen } from 'store/offersSlice'

type OfferButtonState = {
  offerButtonText: string
  offerModalDetails: OfferModalDetails
  offerExpiration: {
    display: string
    isLessThanADay: boolean
    expired: boolean
  }
}

const TP = 'productTemplate.components.ProductPicker.BuyButtonRow'

const getISPrice = (optionVariant?: ISizePickerOptionVariant) => {
  if (!optionVariant || !optionVariant?.instantShipLowestPriceCents?.display) {
    return null
  }
  return optionVariant.instantShipLowestPriceCents.display
}

const getOptionVariantPrice = (optionVariant?: ISizePickerOptionVariant) => {
  if (!optionVariant) {
    return null
  }
  if (
    optionVariant?.lowestPriceCents?.localizedValue &&
    optionVariant?.lowestPriceCents?.localizedValue <
      (optionVariant?.instantShipLowestPriceCents?.localizedValue || Infinity)
  ) {
    return optionVariant.lowestPriceCents.display
  }
  return optionVariant?.instantShipLowestPriceCents?.display
}

const getBuyButtonText = (
  isMarketPricing: boolean,
  isHideFastestToYou: boolean,
  isApparel: boolean,
  optionVariant?: ISizePickerOptionVariant,
) => {
  const selectedOptionVariantPrice = (() => {
    if (isMarketPricing) {
      if (isHideFastestToYou) {
        return optionVariant?.lowestPriceCents?.localizedValue
          ? optionVariant?.lowestPriceCents?.display
          : null
      } else {
        return getOptionVariantPrice(optionVariant)
      }
    } else {
      return getISPrice(optionVariant)
    }
  })()

  if (selectedOptionVariantPrice) {
    const priceNoDecimals = selectedOptionVariantPrice.replace(/\.\d\d$/, '')
    return isApparel ? priceNoDecimals : `${priceNoDecimals}+`
  }

  return t(`${TP}.outOfStock`, 'Out of stock')
}

const BuyButtonRow = () => {
  const isMarketPricing = useFeatureFlag(FeatureFlag.MARKET_PRICING)
  const isHideFastestToYou = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_PDP_HIDE_FASTEST_TO_YOU)
  const isOffersEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_OFFERS)
  const dispatch = useAppDispatch()
  const { isApparel, selectedSize, setActiveProductModal, productTemplate, userOffersForPT } =
    useProductTemplateContext()
  const showBuyUsedButton = isOffersEnabled ? selectedSize?.used : true
  const { country: selectedCountryCode } = useShoppingRegionContext()
  const isShoppingRegionUS = selectedCountryCode === 'US'
  const activeOffers = useAppSelector((state) => getActiveOffers(state))
  const [offerButtonState, setOfferButtonState] = useState<OfferButtonState>({
    offerButtonText: '',
    offerModalDetails: {} as OfferModalDetails,
    offerExpiration: { display: '', isLessThanADay: false, expired: false },
  })
  const newSizeOptionIsDefective = selectedSize?.new?.shoeCondition === 'new_with_defects'
  const displayOfferCTA =
    isOffersEnabled &&
    isShoppingRegionUS &&
    !isApparel &&
    productTemplate?.isOfferable &&
    selectedSize?.new &&
    !newSizeOptionIsDefective

  const handleOfferCTAClick = (offerModalDetails: OfferModalDetails) => {
    // send tracking event
    if (offerModalDetails.operation === 'create') {
      sendTrackingEvent('OFFER_MAKE_TAP', {
        product_template_slug: offerModalDetails.slug,
        size: offerModalDetails.size?.display || '',
        location: 'pdp',
      })
    } else if (offerModalDetails.operation === 'edit') {
      if (offerModalDetails.offer) {
        sendTrackingEvent('OFFER_EDIT_TAP', {
          product_template_slug: offerModalDetails.slug,
          size: offerModalDetails.size?.display || '',
          offer_duration: offerModalDetails.offer.offerDuration,
          offer_amount: `${(offerModalDetails.offer.offerAmount.amountUsdCents || 0) / 100}`,
          pref_currency_offer_amount: `${(offerModalDetails.offer.offerAmount.amount || 0) / 100}`,
          pref_currency: offerModalDetails.offer.offerAmount.currency,
          buy_now: offerModalDetails.lowestPriceCents!.value,
          want_id: offerModalDetails.offer.id,
          location: 'pdp',
        })
      }
    }

    dispatch(setIsOffersModalOpen({ offerModalDetails, status: true }))
  }

  // Upon selected size change, update the Offer button related state
  useEffect(() => {
    const sizeValue = selectedSize?.size.value

    if (!sizeValue) return

    const offerItem =
      userOffersForPT?.sizes?.includes(sizeValue) &&
      activeOffers
        .find((offerList) => offerList.productTemplate.id === userOffersForPT.productTemplate.id)
        ?.items.find((item) => item.size.size === sizeValue)

    const offerButtonText = offerItem
      ? t(`${TP}.editOffer`, 'Edit Offer - {offerPrice}', {
          offerPrice: ` ${formatMoneyObject(offerItem.profileItemData.offer.offerAmount)}`,
        })
      : t(`${TP}.makeOffer`, 'Make Offer')

    const offerModalDetails: OfferModalDetails = offerItem
      ? {
          lowestPriceCents: {
            display: formatMoneyObject(offerItem.lowestPriceCents),
            value: offerItem.lowestPriceCents.amount!,
          },
          minimumOfferCents: productTemplate?.minimumOfferCents,
          name: productTemplate?.name,
          offer: offerItem.profileItemData.offer,
          openFrom: 'pdp',
          operation: 'edit',
          pictureUrl: productTemplate?.pictureUrl,
          productTemplateId: productTemplate?.id,
          size: selectedSize?.size,
          sizeCategory: productTemplate?.sizeCategory || '',
          slug: productTemplate?.slug,
        }
      : {
          lowestPriceCents: selectedSize?.new?.lowestPriceCents,
          minimumOfferCents: productTemplate?.minimumOfferCents,
          name: productTemplate?.name,
          openFrom: 'pdp',
          operation: 'create',
          pictureUrl: productTemplate?.pictureUrl,
          productTemplateId: productTemplate?.id,
          size: selectedSize?.size,
          sizeCategory: productTemplate?.sizeCategory || '',
          slug: productTemplate?.slug,
        }

    const offerExpiration = offerItem
      ? getRemainingOfferTime(offerItem.profileItemData.offer)
      : { display: '', isLessThanADay: false, expired: false }

    setOfferButtonState({
      offerButtonText,
      offerModalDetails,
      offerExpiration,
    })
  }, [selectedSize, userOffersForPT, activeOffers, productTemplate])

  return (
    <BuyButtonsWrapper $doubleColumn={isApparel || !!displayOfferCTA}>
      <div>
        <SizingLabel data-qa={ProductQA.BuyNewSizingLabel}>
          {t(`${TP}.buyNew`, 'Buy New')}
        </SizingLabel>
        <Button
          buttonType="primary2"
          $fill
          disabled={!selectedSize?.new}
          onClick={() => setActiveProductModal('new')}
          qaAttr={`${ProductQA.BuyNew}BuyNowButton`}
          data-testid="buy-new-button"
        >
          {getBuyButtonText(isMarketPricing, isHideFastestToYou, isApparel, selectedSize?.new)}
        </Button>
      </div>
      {displayOfferCTA && (
        <div>
          {
            <OfferExpiration
              className="offer-expiration"
              $urgent={
                offerButtonState.offerExpiration.isLessThanADay ||
                offerButtonState.offerExpiration.expired
              }
            >
              {offerButtonState.offerExpiration.display && (
                <>
                  {' '}
                  {t(`${TP}.expires`, 'Offer expires ')}
                  <span>{offerButtonState.offerExpiration.display}</span>
                </>
              )}
            </OfferExpiration>
          }
          <Button
            buttonType="primary2"
            $fill
            onClick={() => handleOfferCTAClick(offerButtonState.offerModalDetails)}
            data-qa={`${
              offerButtonState.offerModalDetails.operation === 'create' ? 'Make' : 'Edit'
            }OfferButton`}
          >
            {offerButtonState.offerButtonText}
          </Button>
        </div>
      )}

      {!isApparel && showBuyUsedButton && (
        <div>
          <SizingLabel data-qa={ProductQA.BuyUsedSizingLabel}>
            {t(`${TP}.buyUsed`, 'Buy Used')}
          </SizingLabel>
          <Button
            buttonType="primary1"
            $fill
            disabled={!selectedSize?.used}
            onClick={() => setActiveProductModal('used')}
            qaAttr={`${ProductQA.BuyUsed}BuyNowButton`}
            data-testid="buy-used-button"
          >
            {getBuyButtonText(isMarketPricing, isHideFastestToYou, isApparel, selectedSize?.used)}
          </Button>
        </div>
      )}
    </BuyButtonsWrapper>
  )
}

const BuyButtonsWrapper = styled.div<{ $doubleColumn: boolean }>`
  column-gap: 11px;
  row-gap: 20px;
  display: grid;
  grid-template-columns: ${({ $doubleColumn }) => ($doubleColumn ? '1fr 1fr' : '1fr')};
  margin-top: 30px;
`

const OfferExpiration = styled.label<{ $urgent: boolean }>`
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: ${({ $urgent }) => ($urgent ? colors.FC2_RED : colors.FC2_BLACK)};
  height: 37px;
  text-align: right;

  span {
    font-weight: 500;
  }
`

const SizingLabel = styled.label`
  ${fonts.SUBTITLE_2};
  color: ${colors.FC2_BLACK};
  display: block;
  height: 37px;
`

export default BuyButtonRow
