import { ProductQA } from 'shared/dataAttributes'
import { ISizePickerOption } from 'shared/types/ISizePickerOption'

import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import BuyButtonRow from './BuyButtonRow'
import { SizeOptionTile, SizeOptionWrapper } from './ProductPickerStyles'

interface ISizePickerDesktopProps {
  sizePickerOptions: ISizePickerOption[]
  handleSizeOptionClick: (option: ISizePickerOption) => void
}

const SizePickerDesktop = ({
  sizePickerOptions,
  handleSizeOptionClick,
}: ISizePickerDesktopProps) => {
  const { selectedSize } = useProductTemplateContext()
  return (
    <>
      <SizeOptionWrapper data-qa={ProductQA.DesktopSizePicker}>
        {sizePickerOptions.map((option) => (
          <SizeOptionTile
            isSelected={option.size.value === selectedSize?.size.value}
            onClick={() => handleSizeOptionClick(option)}
            key={option.size.value}
          >
            {option.size.display}
          </SizeOptionTile>
        ))}
      </SizeOptionWrapper>
      <BuyButtonRow />
    </>
  )
}

export default SizePickerDesktop
