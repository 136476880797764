import { gql, useQuery } from '@apollo/client'
import RadioButton from 'checkout/components/RadioButton'
import RadioGroup from 'checkout/components/RadioGroup'
import ModalPanel from 'productTemplate/components/ModalPanel'
import ModalPanelText from 'productTemplate/components/ModalPanelText'
import ModalPanelTitle from 'productTemplate/components/ModalPanelTitle'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import ProductQA from 'shared/dataAttributes/ProductQA'
import { colors, fonts } from 'shared/lib'
import { IProductSizeOption } from 'shared/types/IProductSizeOption'
import styled from 'styled-components'

import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { isBadBox } from 'server/utils/productTemplate/productVariantUtils'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { getBoxConditionText } from 'shared/utils/box-condition-utils'
import { t } from 'localization'
import { useEffect } from 'react'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'

const TP = 'productTemplate.components.ProductPicker.panels.BuyConditionalPanel'

const GET_CONDITIONALS = gql`
  query getConditionalProducts($slug: String!, $countryCode: String!, $size: Float!) {
    getConditionalProducts(slug: $slug, countryCode: $countryCode, size: $size) {
      productId
      thumbnailUrl
      images
      size {
        value
        display
      }
      price {
        value
        localizedValue
        display(useGrouping: true, hideEmptyCents: true)
      }
      boxCondition
      productBoxCondition
      shoeCondition
      isInstantShip
      conditions
      notes
    }
  }
`

const ShippingDetail = styled.div`
  ${fonts.SUBTITLE_3};
  color: ${colors.FC2_DARK_GREY};
  margin: 5px 0 10px;
`

const Price = styled.p`
  margin: 0px;
  ${fonts.SUBTITLE_1}
`

const ConditionalNotes = styled.div`
  ${fonts.SUBTITLE_3};
  color: ${colors.FC2_GREY};
`

const ConditionalDescription = styled(ModalPanelText)`
  margin: 5px 0 0;
`

const BuyConditionalPanel = () => {
  const {
    productTemplate,
    selectedConditionalProduct,
    selectedSize,
    setLowPriceConditionalProduct,
    setSelectedConditionalProduct,
  } = useProductTemplateContext()
  const isMarketPricing = useFeatureFlag(FeatureFlag.MARKET_PRICING)
  const shippingDelays = useFeatureFlag(FeatureFlag.SHIPPING_DELAY)
  const hideHighPricedDefectProduct = useFeatureFlag(FeatureFlag.HIDE_HIGH_PRICED_DEFECT_PRODUCT)
  const { specialType } = productTemplate
  const isVintage = specialType === 'vintage'
  const { country: selectedCountryCode } = useShoppingRegionContext()
  const { currencyCode: selectedCurrency } = useShoppingRegionContext()

  const { data, refetch } = useQuery(GET_CONDITIONALS, {
    variables: {
      slug: productTemplate?.slug,
      countryCode: selectedCountryCode,
      size: selectedSize?.size.value,
    },
  })

  // refetch price breakdown when currency changes
  useEffect(() => {
    refetch()
  }, [selectedCurrency])

  const lowestNewNoDefectPrice = (() => {
    if (selectedSize?.new?.shoeCondition !== 'new_no_defects') {
      return Infinity
    }
    const instantShipPrice = selectedSize?.new?.instantShipLowestPriceCents?.value || Infinity
    const shipToVerifyPrice = selectedSize?.new?.lowestPriceCents?.value || Infinity
    let lowPrice = Math.min(instantShipPrice, shipToVerifyPrice)
    // new_no_defect products w/ bad boxCondition are hidden in the buy new toggle
    // we raise the lowestNewNoDefectPrice by 1 to show them in conditionalProducts
    if (isBadBox(selectedSize?.new?.boxCondition)) {
      lowPrice += 1
    }
    return lowPrice
  })()
  const conditionalProducts: IProductSizeOption[] = (data?.getConditionalProducts || []).filter(
    (product: IProductSizeOption) => {
      if (hideHighPricedDefectProduct && Number.isFinite(lowestNewNoDefectPrice)) {
        return product.price.value < lowestNewNoDefectPrice
      }
      return true
    },
  )

  if (!conditionalProducts.length) {
    return null
  }

  // this is always the first product because getConditionalProducts
  // sorts the data by price
  setLowPriceConditionalProduct(data?.getConditionalProducts?.[0])

  return (
    <ModalPanel qaAttr={ProductQA.BuyConditional}>
      <ModalPanelTitle data-qa={ProductQA.ShipBuyWithDefectSectionLabel}>
        {t(`${TP}.buyNewWithDefects`, 'Buy New With Defects')}
      </ModalPanelTitle>
      {!shippingDelays && isMarketPricing && (
        <ShippingDetail data-qa={ProductQA.BuyWithDefectEstimation}>
          {t(`${TP}.shipsToVerify`, 'Ships to us first for verification')}
        </ShippingDetail>
      )}
      <ConditionalDescription data-qa={ProductQA.BuyWithDefectDescription}>
        {isVintage ? (
          <span>
            {t(
              `${TP}.buyWithDefectDescriptionVintage`,
              'This sneaker is new but vintage and may not be suitable for wear due to age. The product or box may have imperfections.',
            )}
          </span>
        ) : (
          <span>
            {t(
              `${TP}.buyWithDefectDescription`,
              'Product is new but box or product may have imperfections.',
            )}
          </span>
        )}
      </ConditionalDescription>
      <RadioGroup name="conditionalSelection">
        {conditionalProducts.map(conditionalProduct => (
          <RadioButton
            checked={selectedConditionalProduct?.productId === conditionalProduct.productId}
            value={`${conditionalProduct.productId}`}
            title={<Price>{conditionalProduct.price?.display}</Price>}
            subtitle={
              <ConditionalNotes>
                {[
                  conditionalProduct.notes,
                  getBoxConditionText(conditionalProduct.boxCondition),
                  conditionalProduct.conditions,
                ]
                  .filter(Boolean)
                  .join(', ')}
              </ConditionalNotes>
            }
            onRadioSelect={() => setSelectedConditionalProduct(conditionalProduct)}
            qaAttr={ProductQA.BuyConditional}
            key={conditionalProduct.productId}
          />
        ))}
      </RadioGroup>
      {isMarketPricing && (
        <ModalPanelText data-qa={ProductQA.BuyWithDefectShippingNote}></ModalPanelText>
      )}
    </ModalPanel>
  )
}

export default BuyConditionalPanel
