import qs from 'qs'
import { useState } from 'react'
import styled from 'styled-components'

import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import { ExperimentState } from 'experiments/state/ExperimentReducer'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { useTikTokPixelTrackEvent } from 'head/hooks/useTikTokPixelTrackEvent'
import { t } from 'localization'
import { logAddToCart } from 'mParticle/ecommerceMParticleEvents'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import ShipView from 'productTemplate/components/ProductPicker/ShipView'
import { useProductTemplateContext } from 'productTemplate/components/ProductTemplateContextProvider'
import { getProductSizeCategory } from 'productTemplate/utils/getProductSizeCategory'
import { Button } from 'shared/components/Button'
import SideSheet from 'shared/components/SideSheet'
import SideSheetModal from 'shared/components/SideSheetModal'
import Spinner from 'shared/components/Spinner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { ProductQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts, media } from 'shared/lib'
import { trackSnapchatAddCart } from 'tracking/snapchatService'
import PricingInfoModal from './PricingInfoModal'
import ProductSideSheetUsedContent from './ProductSideSheetUsedContent'
import { ShoppingRegionSelector } from './ShoppingRegionSelector'

const TP = 'productTemplate.components.ProductPicker.ProductSideSheet'

type TitleProps = {
  name: string
  category: string
  size?: string
}

const Title = ({ name, category, size }: TitleProps) => (
  <Header>
    <ProductName data-qa={ProductQA.SideSheetShoeName}>{name}</ProductName>
    <SizingLabel data-qa={ProductQA.SideSheetSizingLabel}>
      {t(`${TP}.sideSheetSizingLabel`, {
        defaultValue: 'US {category} Size {size}',
        category: getProductSizeCategory(category),
        size,
      })}
    </SizingLabel>
  </Header>
)

const ProductSideSheet = (experimentState: ExperimentState) => {
  const regionSelectorPlacement: EXPERIMENT_NAME = 'web_fc_fully_landed_pricing_copy_placement_test'
  const enableExperiments = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_EXPERIMENTS)
  const enablePricingCopyPlacementExperiment = useFeatureFlag(
    FeatureFlag.TEMP_WEB_FC_ENABLE_FULLY_LANDED_PRICING_COPY_EXPERIMENT,
  )
  const { currencyCode: selectedCurrency } = useShoppingRegionContext()
  const { trackTikTokCheckoutAddCart } = useTikTokPixelTrackEvent()
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false)
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)
  const {
    activeProductModal,
    setActiveProductModal,
    orderInfo,
    productTemplate,
    selectedSize,
    newProductView,
  } = useProductTemplateContext()

  const openPricingModal = () => {
    sendTrackingEvent('PDP_BUY_PANE_PRICING_INFO_TAP', {
      product_template_slug: productTemplate.slug,
    })
    setIsPricingModalOpen(true)
  }

  const closePricingModal = () => {
    setIsPricingModalOpen(false)
  }

  const handleSideSheetClose = () => {
    setActiveProductModal()
    closePricingModal()
    sendTrackingEvent('PDP_BUY_PANE_CLOSE', {
      product_template_slug: productTemplate.slug,
    })
  }

  const handleCheckoutButtonClick = () => {
    try {
      setIsCheckoutLoading(true)
      window.sessionStorage.setItem('recentAdd', `/checkout?${qs.stringify(orderInfo)}`)
      sendTrackingEvent('CART_ADD', {
        is_apparel_product: productTemplate.productCategory !== 'shoes',
        product_template_slug: productTemplate.slug,
        size: selectedSize?.size.display!,
        price: orderInfo?.expectedPriceCents!,
        condition: orderInfo?.productType?.toLowerCase()!,
        currency_code: selectedCurrency,
        instantShip: !!orderInfo?.instantShip,
        lowest_price: selectedSize?.new?.lowestPriceCents?.value || null,
        fastest_price: selectedSize?.new?.instantShipLowestPriceCents?.value || null,
      })
      logAddToCart({
        name: productTemplate.name,
        sku: productTemplate.sku,
        brandName: productTemplate.brand,
        priceCents: orderInfo?.expectedPriceCents!,
        condition: orderInfo?.productType?.toLowerCase(),
      })
      trackSnapchatAddCart({ sku: productTemplate.sku })
      trackTikTokCheckoutAddCart({
        contentCategory: productTemplate.brand,
        contentId: productTemplate.sku,
        price: orderInfo?.expectedPriceCents,
        quantity: 1,
      })
    } catch (error) {
      setIsCheckoutLoading(false)
    }
  }

  return (
    <SideSheet.Responsive
      title={
        <Title
          name={productTemplate.name}
          category={productTemplate.sizeCategory}
          size={selectedSize?.size.display}
        />
      }
      isVisible={!!activeProductModal}
      onClose={handleSideSheetClose}
      isFooterBoxShadowVisible={true}
    >
      <SideSheet.ModalOverlay>
        <SideSheetModal isOpen={isPricingModalOpen} hasPadding={false}>
          <PricingInfoModal closePricingModal={closePricingModal} data-testid="pricingInfoModal" />
        </SideSheetModal>
        <SideSheetModal isOpen={isCheckoutLoading} hasPadding={false} noBorder>
          <Spinner threshold={0} showSpinner={true} />
        </SideSheetModal>
      </SideSheet.ModalOverlay>
      <SideSheet.Body>
        {enableExperiments &&
          enablePricingCopyPlacementExperiment &&
          experimentState[regionSelectorPlacement]?.groupAssigned === 'test' && (
            <StyledShoppingRegionSelector>
              <ShoppingRegionSelector />
            </StyledShoppingRegionSelector>
          )}
        {activeProductModal === 'used' ? (
          <ProductSideSheetUsedContent />
        ) : (
          <ShipView openPricingModal={openPricingModal} />
        )}
      </SideSheet.Body>
      {activeProductModal === 'new' && (
        <SideSheet.Footer>
          <CheckoutButtonPositioner>
            <Button
              buttonType="primary2"
              component="a"
              disabled={!orderInfo || newProductView === 'pickup'}
              $fill
              href={`/checkout?${qs.stringify(orderInfo)}`}
              onClick={handleCheckoutButtonClick}
              qaAttr={ProductQA.BuyNewCheckoutButton}
            >
              {t(`${TP}.checkout`, 'Check Out')}
            </Button>
          </CheckoutButtonPositioner>
        </SideSheet.Footer>
      )}
    </SideSheet.Responsive>
  )
}

export default ProductSideSheet

const StyledShoppingRegionSelector = styled.div`
  padding: 0 20px;
`

const Header = styled.div`
  padding: 0;
`
const ProductName = styled.div`
  ${fonts.SUBTITLE_1};
  text-transform: uppercase;
  margin-bottom: 5px;
  color: ${colors.FC2_BLACK};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const SizingLabel = styled.label`
  ${fonts.SUBTITLE_3};
  color: ${colors.FC2_GREY};
  text-transform: capitalize;
`

const CheckoutButtonPositioner = styled.div`
  padding: 20px 20px 35px;
  ${media.large`
    padding: 20px 30px 35px;
  `}
`
